.drop_zone {
  width: 100%;
  min-height: 6rem;
  border-radius: 10px;
  position: relative;
  display: flex;
  flex-direction: column;
  -webkit-box-align: center;
  align-items: center;
  border: 2px dashed #2b45ed;
  padding: 1.5rem;
  cursor: pointer;
  -webkit-box-flex: 0;
}
